import secureStorage from "./encrypt";
import _ from "lodash";
export const TENANT=process.env.REACT_APP_TENANT || "id";
export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_COMMON_GATEWAY_API || "https://api-id.oto.com/",
	API_KEY: process.env.REACT_APP_API_KEY || "sfanode-ac1faa7b-9fe9-4483-9525-5cc4ce94c639",
};
export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const NAME_VALIDATION = /^[A-Za-z\s]+$/;

export const ALPHA_NUMERIC_VALIDATION = /^[a-zA-Z0-9 ]+$/;

export const ACCOUNT_NO_VALIDATION = /^\d{10,16}$/;

export const SPOUSE_OFFICE_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const SPOUSE_BUSINESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,20}$/;

export const EMERGENCY_CONTACT_NAME_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,50}$/;

export const EMERGENCY_CONTACT_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const MOBILE_VLIDATION_RULE = /^\+\d{1,3}-\d{9,10}$/;

export const PASSWORD_VALIDATION=/^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/;

export const EMAIL_VALIDATION = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const EMAIL_VALIDATION_ADMIN = (TENANT=="fav-id")?/^([A-Za-z0-9_\-\.]+)@(girnarsoft\.co\.in|girnarsoft\.com|favorite\.id|cardekho\.com|triivco\.com)$/ : /^([A-Za-z0-9_\-\.]+)@(oto|otosupport|girnarsoft|cardekho|triivco|favorite)\.([A-Za-z]{2,4}|id|com|co\.in)$/;

export const MARGIN_FOR_OTO_MAX_VALIDATION = 1000000;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	const userAccessFeatures = secureStorage.getItem("userAccess");
	if (userAccessFeatures && userAccessFeatures.length) {
		let isKeyExist = _.findKey(userAccessFeatures, function (v) {
			if (controller && v.controller && action && v.action) {
				return (
					v.controller.toLowerCase() === controller.toLowerCase() &&
					v.action.toLowerCase() === action.toLowerCase()
				);
			}
		});
		isUserHasRights = isKeyExist ? true : false;
		isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
	}

	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};

export const APIV = 2;

export const FINANCIER_PRODUCT_TYPE_OPTIONS = [
	{ label: "UCF", value: "UCF" },
	{ label: "UCRF", value: "UCRF" },
	{ label: "UBRF", value: "UBRF" },
	{ label: "INSURANCE", value: "INSURANCE" },
];
export const TAGGING_ROLES =(TENANT=="id" || TENANT=="tri-id")?[53, 54, 57]:[53, 57, 52, 56];

export const Zone = [
	{ id: 5, value: 'Indonesia  Zone', label: 'Indonesia  Zone' }
];

export const Business = (TENANT=="tri-id") ? [
	{ id: 1, value: 'TriiVCO', label: 'TriiVCO' }
] :(TENANT=="fav-id")? [
	{ id: 1, value: 'Favorite', label: 'Favorite' }
]:[	
	{ id: 1, value: 'OTO', label: 'OTO' }
]

export const Vertical = [
	{ id: 1, value: 'Used Car', label: 'Used Car' }
];
export const defaultBusiness = 1
export const defaultVertical = 1

export const defaultZone = 5

export const VIRTUAL_ADMIN = "Virtual Admin"

export const EmploymentType = [
	{ value: '0', label: 'Off Roll' },
	{ value: '1', label: 'On Roll' },
];

export const BusinessLine = [
	{ value: 'UCF', label: 'Used Car Finance' },
	{ value: 'UCRF', label: 'Used Car Refinance' },
	{ value: 'UBRF', label: 'Used Bike Refinance' },
	{ value: 'insurance', label: 'Insurance' }
];

export const validationForFields = {
	"incentive_percentage": [52],
	"current_occupation": [53],
	"referral_name": [53],
	"company_name": [53, 54, 57],
	"company_address": [54, 57],
	"designation": [53],
	"tagging": (TENANT=="id" || TENANT=="tri-id")?[53, 54, 57]:[53, 57, 52, 56],
	"source": [53, 54, 57],
	"is_tax_booster_enable": [56],
	"rt": [54, 57],
	"rw": [54, 57],
	"bm_grading":[52],
	"agent_wallet_view": (TENANT=="id")?[53,54,57,52,46]:(TENANT=="fav-id")?[53,46,57]:[],
	"whatsapp_mobile_number":[53],
	"secondary_reporting_div_business_line":[],//['UCRF'],
	"secondary_reporting_div":[],//[53, 54, 57]
	"alternative_number":[53]
}
export const invalidNumberValues = ["-", "+", "e", "."];

export const imageWidth = 360;
export const minDateYear = 1970;

export const Role = (TENANT=="id" || TENANT=="tri-id") ? {
	"RBH": 51,
	"BM": 52,
	"Agent": 53,
	"BRO": 54,
	"Virtual Admin": 55,
	"AM": 56,
	"ARO TEST": 57,
	"ARO": 57,
	"ZM": 58,
	"ARCO": 59,
	"VP": 50,
	"PlatinumAgent": 46,
} : {
	"RBH": 51,
	"MM": 52,
	"FA": 53,
	"MO": 57,
	"Virtual Admin": 55,
	"RM": 56,
	"GM": 58,
	"VP": 50,
	"PlatinumAgent": 46,
	"AMF": 60
};

export const RoleId = (TENANT=="id" || TENANT=="tri-id") ? {
	"51": "RBH",
	"52": "BM",
	"53": "Agent",
	"54": "BRO",
	"55": "Virtual Admin",
	"56": "AM",
	"57": "ARO",
	"58": "ZM",
	"59": "ARCO",
	"50": "VP",
	"46": "Alpha Agent"
} : {
	"51": "RBH",
	"52": "MM",
	"53": "FA",
	"57": "MO",
	"55": "Virtual Admin",
	"56": "RM",
	"58": "GM",
	"50": "VP",
	"46": "Platinum Agent",
	"60": "AMF"
}

export const BasicDetailConfig = {
	"source": [
		{
			"value": "Website",
			"label": "Website"
		},
		{
			"value": "BM",
			"label": "BM"
		},
		{
			"value": "referral_agent",
			"label": "Referral Agent"
		},
		{
			"value": "Others",
			"label": "Others"
		}
	],
	"source_agent": [
		{
			"value": "Website",
			"label": "Website"
		},
		{
			"value": "BM",
			"label": "BM"
		},
		{
			"value": "referral_agent",
			"label": "Referral Agent"
		},
		{
			"value": "mass_hiring",
			"label": "Mass Hiring"
		},
		{
			"value": "join_us",
			"label": "Join Us"
		},
		{
			"value": "user_agent_enquiry",
			"label": "User Agent Enquiry"
		},
		{
			"value": "competitor",
			"label": "Competitor"
		},
		{
			"value": "oto_agent",
			"label": "Oto Agent"
		},
		{
			"value": "rampwin",
			"label": "Rampwin"
		},
		{
			"value": "Others",
			"label": "Others"
		}
	],
	"document": [
		{
			"doc_id": "1",
			"doc_title": "Selfie with KTP",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247543.png",
			"doc_bg_color": "#BFF8FC"
		},
		{
			"doc_id": "2",
			"doc_title": "KTP",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247544.png",
			"doc_bg_color": "#FFF1D6"
		},
		{
			"doc_id": "3",
			"doc_title": "Bank Statement",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247545.png",
			"doc_bg_color": "#D8EAFF"
		},
		{
			"doc_id": "4",
			"doc_title": "NPWP",
			"doc_min": "1",
			"doc_max": "1",
			"doc_required": "1",
			"doc_logo_url": "https://otobackendbeta.storage.googleapis.com/SFA/12/1648618247545.png",
			"doc_bg_color": "#C5F8EB"
		}
	],
	"tagging_roles": {
		"Agent": [
			{
				"value": "regular",
				"label": "Regular"
			},
			{
				"value": "dsoo",
				"label": "DSOO"
			},
			{
				"value": "dse",
				"label": "DSE"
			}
		],
		"ARO": [
			{
				"value": "sr_aro",
				"label": "Sr. ARO"
			},
			{
				"value": "aro",
				"label": "ARO"
			},
			{
				"value": "jr_aro",
				"label": "Jr. ARO"
			}
		],
		"BRO": [
			{
				"value": "sr_bro",
				"label": "Sr. BRO"
			},
			{
				"value": "bro",
				"label": "BRO"
			},
			{
				"value": "jr_bro",
				"label": "Jr. BRO"
			}
		]
	},
	"business_line": [
		{
			"value": "UCF",
			"label": "Used Car Finance"
		},
		{
			"value": "UCRF",
			"label": "Used Car Refinance"
		},
		{
			"value": "UBRF",
			"label": "Used Bike Refinance"
		},
		{
			"value": "insurance",
			"label": "Insurance"
		}
	],
	"hiring_source": [
		{
			"value": "existing",
			"label": "Existing"
		},
		{
			"value": "new",
			"label": "New"
		}
	]
}


export const VP_ID = 50

export const PAGINATION = {
	PAGE_SIZE: 50
}

export const MANAGER_ROLES = [
	{ "name": "ARO", "id": 57, "rank": 8 },
	{ "name": "ARCO", "id": 59, "rank": 7 },
	{ "name": "BM", "id": 52, "rank": 6 },
	{ "name": "AM", "id": 56, "rank": 5 },
	{ "name": "ZM", "id": 58, "rank": 4 },
	{ "name": "RBH", "id": 51, "rank": 3 },
	{ "name": "VP", "id": 50, "rank": 2 },
	{ "name": "Virtual Admin", "id": 55, "rank": 1 },
]
export const SFA_URL = process.env.REACT_APP_SFA_URL || "https://sfa.oto.com/";
export const EXECUTIVE_ROLES = [53, 54];
export const SFA_NEW_URL = process.env.REACT_APP_SFA_NEW_URL || "https://sfa.oto.com/";
export const ESIGN_BASE_URL = SFA_NEW_URL + "user/esign_agreement_log_view";
export const SFA_LOGIN_URL = SFA_NEW_URL + "login";
export const FINANCIER_API_KEY = "d77bc90f-6824-47da-94c2-2930de73daad"
export const VISIT_EXECUTIVE_ROLES =  [52, 54, 57, 56, 51, 58];
export const STATUS= [
	{id : 1, label : 'Active', value : 'active'},
	{id : 2, label : 'Inactive', value : 'inactive'},
]
export const FORMAT_BULK_UPLOAD = [
    {
        "SFA ID": "1",
        "ASLI RI Mobile Number 1": "8888888888",
        "ASLI RI Mobile Number 2": "8888888888",
        "ASLI RI Mobile Number 3": "8888888888",
        "ASLI RI Mobile Number 4": "8888888888",
		"ASLI RI Mobile Number 5": "8888888888"
    }
]
export const PROGRESSION_FILTER_ROLES = [56, 58, 51]
export const ROLE_ABOVE_BM = [50, 51, 56, 58]
export const ACCOUNT_MANAGER_BUSINESS_LINE = "UCF"

export const ADMIN_ROLES = {
    super_admin: 1,
    master_admin: 2,
    admin: 3,
    viewer: 4,
    sales_support: 5,
    onboarding_specialist: 6
};

export const ADMIN_ROLE_ID = {
    1: 'super_admin',
    2: 'master_admin',
    3: 'admin',
    4: 'viewer',
    5: 'sales_support',
    6: 'onboarding_specialist',
};

export const REJECT_STATUS = '3'
export const INACTIVE_STATUS = '0'
export const MONTH_NAMES = [
	{ id: 1, label: 'January' },
	{ id: 2, label: 'February' },
	{ id: 3, label: 'March' },
	{ id: 4, label: 'April' },
	{ id: 5, label: 'May' },
	{ id: 6, label: 'June' },
	{ id: 7, label: 'July' },
	{ id: 8, label: 'August' },
	{ id: 9, label: 'September' },
	{ id: 10, label: 'October' },
	{ id: 11, label: 'November' },
	{ id: 12, label: 'December' }
];

export const TARGET_UPLOAD_SAMPLE = [
	{
		"SFA ID": "1",
		"Overall Target": "13",
		"NIM Target": "9",
		"Month": "April",
	}
]

export const ECA_AGENT_TAGGING = ['eca', 'eca_l1']
export const REDIRECT_URL = window.location.origin + "/login"
export const MOBILE_VALIDATION=/^8[0-9]{7,11}$/;
