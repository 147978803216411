import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import MasterService from '../../services/MasterService';
import DateFormat from 'dateformat';
import InputField from '../common/Input';
import ReactPaginate from 'react-paginate';
import { Role, PAGINATION, TENANT } from '../../config/constant';
import Loader from '../common/loader';
import ToggleSwitch from '../element/ToggleSwitch';

const ViewReportees = (props) => {
    let { user_id } = useParams()
    // let { stateList, cityList } = props
    const [loading, setLoading] = useState(false);
    const [reportees, setReportees] = useState([]);
    const [roleId, setRoleId] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize] = useState(PAGINATION.PAGE_SIZE || 50);
    const [secondaryHierarchy, setSecondaryHierarchy] = useState(false);
    const history = useNavigate();



    const getClassName = (statusId) => {
        let statusClass = '';
        if (statusId == 0) {
            statusClass = 'status-inactive';
        } else if (statusId == 1) {
            statusClass = 'status-active';
        } else if (statusId == 2) {
            statusClass = 'status-inactive';
        } else if (statusId == 3) {
            statusClass = 'status-inprocess';
        } else if (statusId == 4) {
            statusClass = 'status-inprocess';
        }
        return statusClass;
    }

    useEffect(() => {
        if (user_id) {
            getReportees();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id, roleId, searchKey, pageNo, pageSize, secondaryHierarchy])

    useEffect(() => {
        setOffset(0);
        setPageNo(1);
    }, [roleId, searchKey])

    const getReportees = () => {
        setLoading(true);
        MasterService.post('/sfa/user/get_reportees_list', { "user_id": user_id, "role_id": roleId, "search_key": searchKey, "page_no": pageNo, "page_size": pageSize, "is_secondary": secondaryHierarchy ? 1 : 0 })
            .then(function (response) {
                setReportees(response?.data?.data || {});
                let tempPageCount = Math.ceil(response.data.pagination.total / PAGINATION.PAGE_SIZE);
                setPageCount(tempPageCount);
                setLoading(false);
            })
            .catch(function (response) {
                setLoading(false);
            });
    }

    const updateRoleId = (role_id) => {
        if (role_id) {
            setRoleId([role_id]);
        } else {
            setRoleId([]);
        }

    }

    const handlePageClick = (page_no) => {
        setOffset(page_no);
        setPageNo(page_no + 1);
    }

    const handleSerachKeyInput = (search_key) => {
        setSearchKey(search_key);
    }

    const handleNameClick = (user_id) => {
        history(`/view-profile/${user_id}`);
    }

    const handleSecondaryHierarchy = () => {
        setSecondaryHierarchy(prev => !prev);
    }

    return (
        <React.Fragment>
            <div className='reportess-tab-outer'>
                {loading ? <Loader /> : null}

                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    {/* {TENANT == "id" &&
                        <div className="row">
                            <fieldset className="col-md-4 col-xs-push-10">
                                <div className="material toggle_outer">
                                    <span className='m-md-r booster-text'>Show Primary</span>
                                    <ToggleSwitch toggleChecked={!secondaryHierarchy} onClick={() => { handleSecondaryHierarchy() }} />
                                </div>
                            </fieldset>
                        </div>
                    } */}
                    <div className="reportess-tabs">
                        <Nav variant="pills" className="flex-column tab-bg">
                            {(TENANT=="id") ?
                                <div className="tab-list">
                                    <Nav.Item onClick={() => updateRoleId(0)} >
                                        <Nav.Link eventKey="first">All ({reportees?.reporteeCount?.ALL || "0"})  </Nav.Link>
                                    </Nav.Item>
                                    {!secondaryHierarchy &&
                                        <Nav.Item onClick={() => updateRoleId(Role.ZM)}>
                                            <Nav.Link eventKey="second"> ZM ({reportees?.reporteeCount?.ZM || "0"})</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {!secondaryHierarchy &&
                                        <Nav.Item onClick={() => updateRoleId(Role.RBH)}>
                                            <Nav.Link eventKey="third">RBH ({reportees?.reporteeCount?.RBH || "0"})</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {!secondaryHierarchy &&
                                        <Nav.Item onClick={() => updateRoleId(Role.AM)}>
                                            <Nav.Link eventKey="fourth">AM ({reportees?.reporteeCount?.AM || "0"})</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {!secondaryHierarchy &&
                                        <Nav.Item onClick={() => updateRoleId(Role.BM)}>
                                            <Nav.Link eventKey="fifth">BM ({reportees?.reporteeCount?.BM || "0"})</Nav.Link>
                                        </Nav.Item>
                                    }
                                    <Nav.Item onClick={() => updateRoleId(Role.BRO)}>
                                        <Nav.Link eventKey="sixth">BRO ({reportees?.reporteeCount?.BRO || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.Agent)}>
                                        <Nav.Link eventKey="seventh">Agent ({reportees?.reporteeCount?.Agent || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.ARO)}>
                                        <Nav.Link eventKey="eighth">ARO ({reportees?.reporteeCount?.ARO || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.ARCO)}>
                                        <Nav.Link eventKey="ninth">ARCO ({reportees?.reporteeCount?.ARCO || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.PlatinumAgent)}>
                                        <Nav.Link eventKey="tenth">Alpha Agent ({reportees?.reporteeCount?.PlatinumAgent || "0"})</Nav.Link>
                                    </Nav.Item>

                                </div>
                                :(TENANT=="fav-id") ?
                                <div className="tab-list">
                                    <Nav.Item onClick={() => updateRoleId(0)} >
                                        <Nav.Link eventKey="first">All ({reportees?.reporteeCount?.ALL || "0"})  </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.GM)}>
                                        <Nav.Link eventKey="second"> GM ({reportees?.reporteeCount?.ZM || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.RBH)}>
                                        <Nav.Link eventKey="third">RBH ({reportees?.reporteeCount?.RBH || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.RM)}>
                                        <Nav.Link eventKey="fourth">RM ({reportees?.reporteeCount?.AM || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.MM)}>
                                        <Nav.Link eventKey="fifth">MM ({reportees?.reporteeCount?.BM || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.MO)}>
                                        <Nav.Link eventKey="sixth">MO ({reportees?.reporteeCount?.ARO || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.FA)}>
                                        <Nav.Link eventKey="seventh">FA ({reportees?.reporteeCount?.Agent || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.PlatinumAgent)}>
                                        <Nav.Link eventKey="tenth">Platinum Agent ({reportees?.reporteeCount?.PlatinumAgent || "0"})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => updateRoleId(Role.AMF)}>
                                        <Nav.Link eventKey="eleven">AMF ({reportees?.reporteeCount?.AMF || "0"})</Nav.Link>
                                    </Nav.Item>
                                </div>:
                                 <div className="tab-list">
                                 <Nav.Item onClick={() => updateRoleId(0)} >
                                     <Nav.Link eventKey="first">All ({reportees?.reporteeCount?.ALL || "0"})  </Nav.Link>
                                 </Nav.Item>
                                 {!secondaryHierarchy &&
                                     <Nav.Item onClick={() => updateRoleId(Role.ZM)}>
                                         <Nav.Link eventKey="second"> ZM ({reportees?.reporteeCount?.ZM || "0"})</Nav.Link>
                                     </Nav.Item>
                                 }
                                 {!secondaryHierarchy &&
                                     <Nav.Item onClick={() => updateRoleId(Role.RBH)}>
                                         <Nav.Link eventKey="third">RBH ({reportees?.reporteeCount?.RBH || "0"})</Nav.Link>
                                     </Nav.Item>
                                 }
                                 {!secondaryHierarchy &&
                                     <Nav.Item onClick={() => updateRoleId(Role.AM)}>
                                         <Nav.Link eventKey="fourth">AM ({reportees?.reporteeCount?.AM || "0"})</Nav.Link>
                                     </Nav.Item>
                                 }
                                 {!secondaryHierarchy &&
                                     <Nav.Item onClick={() => updateRoleId(Role.BM)}>
                                         <Nav.Link eventKey="fifth">BM ({reportees?.reporteeCount?.BM || "0"})</Nav.Link>
                                     </Nav.Item>
                                 }
                                 <Nav.Item onClick={() => updateRoleId(Role.BRO)}>
                                     <Nav.Link eventKey="sixth">BRO ({reportees?.reporteeCount?.BRO || "0"})</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item onClick={() => updateRoleId(Role.Agent)}>
                                     <Nav.Link eventKey="seventh">TOS ({reportees?.reporteeCount?.Agent || "0"})</Nav.Link>
                                 </Nav.Item>                                                                 
                             </div>
                            }
                        </Nav>
                        <fieldset className="search-bx">
                            <div className="material">
                                <InputField
                                    id="search_by"
                                    type="text"
                                    className="form-input"
                                    placeholder="Search By Name, Email or Mobile"
                                    name="search_by"
                                    autoComplete="off"
                                    value={searchKey}
                                    label="Search"
                                    onChange={(e) => handleSerachKeyInput(e.target.value)}
                                />
                                <i className="ic-search"></i>
                            </div>
                        </fieldset>
                    </div>
                            <div className='reportees-data-table data-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            {/* <th ></th> */}
                                            <th className="sorting sorting_asc" >Name</th>
                                            <th >SFA ID</th>
                                            <th >Email</th>
                                            <th >Mobile</th>
                                            <th >Role</th>
                                            <th >Reporting to Role</th>
                                            <th >Reporting to</th>
                                            <th >Status</th>
                                            <th className="sorting sorting_asc">Created Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {!(reportees && reportees.reporteeList && reportees.reporteeList.length !== 0) && <tr><td align="center" colSpan="7"><h6 className="text-center text-danger text-bold">No Records Found</h6></td></tr>}
                                        {
                                            reportees && reportees.reporteeList && reportees.reporteeList.length !== 0 && reportees.reporteeList.map((userdata, k) => (
                                                <tr key={k} >
                                                    {/* <td></td> */}
                                                    <td>
                                                        <a href={() => false} onClick={() => handleNameClick(userdata.user_id_hash)} style={{ color: "rgba(0,0,0,0.6)", cursor: "pointer" }}>
                                                            {userdata.name}
                                                        </a>
                                                    </td>
                                                    <td>{userdata.user_id}</td>
                                                    <td>{userdata.email}</td>
                                                    <td>{userdata.mobile}</td>
                                                    <td>{userdata.role_name}</td>
                                                    <td>{userdata.reporting_to_role}</td>
                                                    <td>{userdata.reporting_to}</td>
                                                    <td>
                                                        <span className={getClassName(userdata.status)}>{userdata.status_name}</span>
                                                    </td>
                                                    <td>{DateFormat(new Date(userdata.created_date), 'dd mmm, yyyy')}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='pagination-bx' id="dealerList">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        forcePage={offset}
                                        pageRangeDisplayed={5}
                                        onPageChange={(e) => handlePageClick(e.selected)}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                </div>
                            </div>
                </Tab.Container>
            </div>

        </React.Fragment>
    )
}
export default ViewReportees;